<template>
  <div>
    <div class="title pd-t-20 pd-b-20 fs-16">{{title}}</div>
    <div class="mg-t-10  display-flex  flex-wrap">
      <div class=".w-b-30 h-30 align-items-center text-align-center fs-12 mg-r-28 mg-b-20" v-for="(item, index) in getModel.flds" :key="index">
        <div class="fs-20 lh-30 bg-f9f c-0 w-80 bdr-5" v-if="title === '字段对应的标识符'">{{contactStr(index)}} </div>
        <div class="lh-30 bg-f9f c-0 w-80 bdr-5" v-else>第{{index + 1}}列</div>
        <div class="el-icon-right lh-30  mg-r-20 mg-l-10"></div>
        <div class="lh-30 bg-f3f c-0 pd-l-30 pd-r-30 bdr-5 mg-r-20">{{item.name}}</div>
      </div>
    </div>
    <div class="mg-t-20">
      <div class="fs-16">卡牌之间</div>
      <div class="mg-t-20 align-items-center">
        <span class="c-9 mg-r-40">默认</span>
        <span class="text-align-center w-80 h-20 lh-20 pd-t-5 pd-b-5 pd-l-20 pd-r-20 bg-e bdr-6">换行</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props :{
    title: {
      type: String,
      default:''
    },
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters(['getModel']),
    contactStr () {
      return function (index) {
        let str = ''
        for(let i= 0; i<=index; i++) {
          str+='*'
        }
        return str
      }
    }
  }
}
</script>

<style scoped>
 /* .custom >>>.el-input__inner {
  height: 30px;
}
.custom >>>.el-input__icon {
  line-height: 30px;
} */
</style>
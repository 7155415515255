<template>
  <div id="app" class="h-fill">
    <div v-if="showNavigate && loginUser && $route.path !== '/make'"
         class="position-fixed t-0 l-0 r-0 shadow-2 h-60 z-index-999 bg-f">
      <div class="container h-60 display-flex justify-content-space-between align-items-center"
           style="width: 1200px;">
        <div class="display-flex align-items-center fs-18">
          <a href="/space" class="c-primary mg-r-80 mg-l-1 c-0">
            <img src="/static/image/navbar_home_selected.png" width="26">
          </a>
          <a @click="onLinkResourceWebClick" class="c-primary mg-r-80 c-0">
            <img src="/static/image/navbar_card_normal.png" width="26">
          </a>
          <el-dropdown :show-timeout="0" v-if="showImportOption">
            <span class="el-dropdown-link display-inline-block">
              <!--<i class="el-icon-plus text-strong c-0 fs-18"></i>-->
              <img src="/static/image/navbar_add_normal.png" width="24">
            </span>
            <el-dropdown-menu slot="dropdown">
              <!--<el-dropdown-item>添加卡牌</el-dropdown-item>-->
              <el-dropdown-item @click.native="onCreateDeckClick">创建记忆库</el-dropdown-item>
              <el-dropdown-item @click.native="onImportClick">导入文件</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="notice.message">
          <div>
            <span class="c-app-orange mg-r-10 cursor-pointer" @click="onClickNotice(notice.url)">🔊{{
                notice.message
              }}</span>
            <i class="el-icon-close cursor-pointer c-8" title="关闭" @click="onClickReadNotice()"></i></div>
        </div>
        <div class="position-relative h-fill display-flex align-items-center">
          <a @click="onVipShowClick" title="开通会员" class="c-1 mg-l-10 mg-r-10">
            <img :class="(loginUser.vip && loginUser.vip.vipAvailable)?'':'img_gray'"
                 src="/static/image/super_heroes_normal@2x.png" height="25"/>
          </a>
          <a class="h-fill display-flex mg-l-50 align-items-center" @mouseleave="showPersonalPanel = false"
             @mouseover="showPersonalPanel = true">
            <!--<span class="c-9">公测版 v{{config.version}}</span>-->
            <img class="w-35 h-35 mg-l-20 bdr-circular" :src="loginUser.avatar"/>
            <transition name="el-zoom-in-center">
              <div v-if="loginUser" v-show="showPersonalPanel"
                   class="position-absolute bg-f t-50 bdr-15 pd-l-30 pd-r-30 pd-t-20 pd-b-20 "
                   style="right: -20px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                   background-image: linear-gradient(#C2F8F8,#FFFFFF 50%);">
                <div class="w-400">
                  <div class="align-items-center">
                    <div>
                      <img :src="loginUser.avatar" class="w-80 h-80 pd-3 bg-f bdr-circular mg-r-30"/>
                    </div>
                    <div>
                      <div class="fs-22">
                        {{ loginUser.nickname }}
                      </div>
                      <div class="c-8 fs-14 pd-t-10" v-if="loginUser.vip.openVip">
                        会员有效期：{{ loginUser.vip.isForever ? '永久有效' : loginUser.vip.vipEndAt }}
                        <span v-if="!loginUser.vip.vipAvailable">(已过期)</span>
                      </div>
                      <div class="c-info fs-14 pd-t-10">ID:{{ loginUser.userId }}</div>
                      <div class="c-info fs-14">{{ loginUser.sign }}</div>
                    </div>
                  </div>
                  <a @click="onMyResourceClick" class="display-block c-6 fs-18 mg-t-36">我的资源</a>
                  <a @click="onSettingsClick" class="display-block c-6 fs-18 mg-t-36">设置中心</a>
                  <!--                  <a @click="onSetUserLabelClick" class="display-block c-6 fs-18 mg-t-36">内容偏好</a>-->
                  <a @click="onMemoryHomeClick" class="display-block c-6 fs-18 mg-t-36">访问官网</a>
                  <a @click="onFeedbackClick" class="display-block c-6 fs-18 mg-t-36">问题反馈</a>
                  <a @click="onLogoutClick" class="display-block c-6 fs-18 mg-t-36">退出登录</a>
                </div>
              </div>
            </transition>
          </a>
        </div>
      </div>
    </div>
    <div class="h-fill" :class="showNavigate?'pd-t-80':''">
      <keep-alive>
        <router-view v-if="$route.meta.KeepAlive"/>
      </keep-alive>
      <router-view v-if="!$route.meta.KeepAlive"/>
    </div>
    <el-dialog :visible.sync="showImportDialog" :before-close="beforeUploadClose" :close-on-click-modal="false"
               :close-on-press-escape="false" width="650px">
      <div slot="title">
        <h1 class="text-align-center">
          导入文件
        </h1>
        <div class="text-align-center">支持 .card / .apkg / .colpkg 文件</div>
      </div>
      <div class="justify-content-center">
        <el-upload drag :limit="1"
                   accept=".apkg,.colpkg,.card"
                   :on-progress="onUploadProgress"
                   :on-success="onUploadSuccess"
                   :before-upload="beforeUpload"
                   :on-error="onUploadError"
                   :show-file-list="false"
                   :file-list="fileList"
                   :headers="uploadHeaders"
                   :action="apiBaseUrl+'/space.index/importFile'">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div v-if="uploadProgress" class="el-upload__tip text-align-center" slot="tip">
            上传进度 <span class="display-inline-block w-36 text-align-right">{{ uploadProgress }}% </span>
          </div>
        </el-upload>
      </div>
    </el-dialog>
    <vip ref="vip"></vip>
    <user-label ref="myUserLabel"></user-label>
  </div>
</template>

<script>
import request from './libs/api.request'
import storage from './libs/storage'
import config from './libs/conf'
import vip from "./components/Vip";
import userLabel from "./components/UserLabel";

export default {
  name: 'App',
  components: {userLabel, vip},
  data() {
    return {
      loginUser: null,
      authToken: null,
      showNavigate: true,
      showImportDialog: false,
      showPersonalPanel: false,
      apiBaseUrl: request.baseUrl,
      uploadProgress: 0,
      uploadingStatus: 0,//0未上传  1上传中  2导入中
      fileList: [],
      uploadHeaders: {},
      config: config,
      onScrollBottomEvent: false,
      showImportOption: false,
      notice: [],
    }
  },
  methods: {
    onSetUserLabelClick() {
      if (this.loginUser) {
        this.$refs.myUserLabel.onChangeShow();
      } else {
        this.$store.commit('showLoginDialog', true)
      }
    },
    onMemoryHomeClick() {
      window.open('https://ankimemory.com')
    },
    onLinkResourceWebClick() {
      window.open('https://file.ankichinas.cn?app-token=' + this.authToken);
    },
    beforeUploadClose(done) {
      if (this.uploadingStatus > 0) {
        this.$confirm('文件正在上传，是否取消？', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
          beforeClose: (action, instance, innerDone) => {
            if (action === 'confirm') {
              this.uploadingStatus = 0;
              this.uploadProgress = 0;
              this.showImportDialog = false;
              innerDone();
            } else {
              innerDone();
            }
          }
        });
      } else {
        done();
      }
    },
    showNavigateBar() {
      this.showNavigate = true;
    },
    onVipShowClick() {
      this.$refs.vip.onChangeShow();
    },
    onFeedbackClick() {
      window.open('https://mp.weixin.qq.com/s?__biz=MzU2OTg0NTAxMw==&mid=2247483677&idx=1&sn=d53cc44aca3b160a8dccba741cff60d2&chksm=fcf93567cb8ebc71c3ec689cb443bf25f19927eb29e8345f6eefa97cfd297e5ea0bab4bbe7ff#rd');
    },
    hideNavigateBar() {
      this.showNavigate = false;
    },
    beforeUpload() {
      this.uploadingStatus = 1;
    },
    onUploadProgress(event) {
      this.uploadProgress = Math.min(parseInt(event.percent), 99);
    },
    onUploadSuccess(response) {
      this.uploadProgress = 100;
      console.log('onUploadSuccess');
      if (response.code === 0) {
        this.uploadingStatus = 2;
        let key = response.data.key;
        let progress = 0;
        let loading = this.$loading({text: '请勿做其他操作，当前处理进度：' + progress + '%'});
        let interval = setInterval(() => {
          request.request({
            url: '/space.index/checkImportStatus',
            params: {key}
          }).then(data => {
            if (data.status > 0) {
              loading.close();
              if (interval) {
                clearInterval(interval);
                interval = null;
              }
              if (data.status === 2) {
                this.$message.error('导入失败！')
              } else if (data.status === 1) {
                if (this.uploadingStatus === 2) {
                  if (data.did > 0) {
                    this.$confirm('导入完成！', '提示', {
                      confirmButtonText: '前往学习',
                      cancelButtonText: '继续导入',
                      type: 'success',
                      beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                          done();
                          this.$router.push({path: '/space/deck/' + data.did});
                        } else {
                          done();
                          this.onImportClick();
                        }
                      }
                    })
                  } else {
                    this.$confirm('导入完成！', '提示', {
                      confirmButtonText: '继续导入',
                      cancelButtonText: '完成',
                      type: 'success',
                      beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                          done();
                          this.onImportClick();
                        } else {
                          done();
                          location.reload();
                        }
                      }
                    })
                  }
                }
              }

              this.uploadingStatus = 0;
              this.uploadProgress = 0;
              this.fileList = [];
              this.showImportDialog = false;
            } else {
              console.log('>>>' + data.progress);
              loading.text = '请勿做其他操作，当前处理进度：' + data.progress + '%';
            }
          }).catch(() => {
          })
        }, 1000)
      } else {
        this.uploadingStatus = 0;
        this.uploadProgress = 0;
        this.fileList = [];
        this.$message.error(response.message)
      }
    },
    onUploadError() {
      this.fileList = [];
      this.uploadingStatus = 0;
      this.$message.error('上传失败，请重试或检查网络')
    },
    onMyResourceClick() {
      this.showPersonalPanel = false;
      let url = '';
      // if (process.env.NODE_ENV === 'production') {
      url = 'https://file.ankichinas.cn/?app-token=' + this.authToken + '&replace=' + encodeURIComponent("/my?op=resource");
      // } else {
      //   url = 'https://dev-file.ankichinas.com/?app-token=' + this.authToken + '&replace=' + encodeURIComponent("/my?op=resource");
      // }
      // console.log(url);
      window.open(url);
    },
    onSettingsClick() {
      this.showPersonalPanel = false;
      let url = '';
      url = 'https://file.ankichinas.cn/?app-token=' + this.authToken + '&replace=' + encodeURIComponent("/my?op=settings");
      window.open(url);
    },
    onLogoutClick() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.$cookies.remove('authToken');
            storage.setUserInfo(null);
            location.reload();
          } else {
            done();
          }
        }
      })
    },
    fetchServerUserInfo() {
      let requestStatus = 0;
      let loading = null;
      setTimeout(() => {
        if (requestStatus === 0) {
          loading = this.$loading({text: '加载中'});
        }
      }, 800);
      request.request({
        url: 'user/getInfo'
      }).then(data => {
        requestStatus = 1;
        if (loading) {
          loading.close();
        }
        this.loginUser = data;
        storage.setUserInfo(data)
      }).catch((err) => {
        requestStatus = 1;
        if (loading) {
          loading.close();
        }
        if (err && err.code > 400 && err.code < 404) {
          this.$message.error('登录已过期，请重新登录');
          this.loginUser = null;
          storage.setUserInfo(null);
          this.$cookies.remove('authToken');
          this.$router.push({path: '/login'});
        } else {
          this.$alert('请检查网络！', '', {
            callback: () => {
              location.reload()
            }
          })
        }
      })
    },
    onCreateDeckClick() {
      if (!this.loginUser.vip || (this.loginUser.vip && !this.loginUser.vip.vipAvailable)) {
        if (Object.keys(this.$store.state.decks).length >= this.loginUser.nonmember.decksCount) {
          let count = this.loginUser.nonmember.decksCount;
          this.$alert('记忆库超过' + count + '个，需开通会员不限个数哦🌹', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.onVipShowClick();
            }
          });
          return;
        }
      }
      this.$prompt('', '创建新的记忆库', {
        confirmButtonText: '创建',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          console.log(instance.inputValue);
          if (action === 'confirm' && instance.inputValue) {
            let loading = this.$loading();
            request.request({
              url: '/space.deck/createDeck',
              method: 'post',
              data: {
                name: instance.inputValue
              }
            }).then((data) => {
              location.reload()
            }).catch((e) => {
              done();
              loading.close();
              this.$message.error(e);
            })
          } else {
            done();
          }
        }
      });
    },
    onImportClick() {
      // this.$message.info('该功能优化中，请使用APP导入');
      this.uploadProgress = 0;
      this.showImportDialog = true;
    },
    getNotice() {
      let that = this;
      request.request({
        url: '/space.index/notice',
        params: {},
      }).then((data) => {
        console.log(data);
        that.notice = data;
      }).catch((e) => {
        console.log(e);
      })
    },
    onClickNotice(url) {
      //window.open(url);
      this.onVipShowClick();
    },
    onClickReadNotice() {
      let that = this;
      request.request({
        url: '/space.index/readNotice',
        params: {},
      }).then((data) => {
        console.log(data)
        setTimeout(function () {
          that.getNotice();
        }, 100);
      }).catch((e) => {
        console.log(e);
      })
    },
  },
  mounted() {
    console.log("process.env.NODE_ENV = " + process.env.NODE_ENV);
    if (this.authToken) {
      this.uploadHeaders = {authorization: this.authToken}
      this.fetchServerUserInfo();
      this.getNotice();
    } else {
      if (this.$route.path !== '/make' && this.$route.path !== '/login'
        && this.$route.path !== '/qqcb' && this.$route.path !== '/' && this.$route.path !== '/h5') {
        this.$router.replace({path: '/login'})
      }
    }
  },
  created() {
    this.authToken = this.$cookies.get('authToken');
    let appToken = this.$route.query['app-token'];
    if (appToken && this.authToken !== appToken) {
      this.authToken = appToken;
    }

    if (this.authToken) {
      this.$cookies.set('authToken', this.authToken, 86400 * 90);
    }

    this.loginUser = storage.getUserInfo();
  },
}
</script>

<style>

html, body {
  height: 100%;
}

html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-thumb {
  background: rgba(40, 190, 160, 0.2);
  border-radius: 5px;
}

html::-webkit-scrollbar-thumb:hover {
  background: rgba(40, 190, 160, 0.2);
  border-radius: 5px;
}


html::-webkit-scrollbar-track { /*滚动条底层颜色*/
  -webkit-box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 0;
  background: #FFFFFF;
}

兼容Firefox、IE
html {
  scrollbar-width: 5px;
  scrollbar-base-color: green;
  scrollbar-track-color: red;
  scrollbar-arrow-color: blue;
  border-radius: 5px;
}

.handled .el-progress-bar__outer {
  background-color: #a5d5cb !important;
}

.img_gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.el-dialog {
  border-radius: 10px !important;
}

</style>

<template>
  <div>
    <div class="container mg-t-80 mg-b-80" v-for="item in list" :key="item.id">
      <div class=" fs-40 text-strong text-align-center">{{item.title}}</div>
      <div class="text-align-center fs-16 mg-t-20 pd-l-60 pd-r-60">
        {{item.desc}}
      </div>
      <div class="text-align-center">
        <img :src="item.imgUrl" width="100%">
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    list :{
      type:Array,
      default: () => []
    }
  }
}
</script>
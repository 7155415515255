<template>
  <div class="">
    <div class="c-9">支持Excel、csv表格文件</div>
    <div class="justify-content-center mg-40">
      <el-upload
          drag
          accept=".xlsx,.xls,.csv"
          :on-progress="onUploadProgress"
          :on-success="onUploadSuccess"
          :before-upload="beforeUpload"
          :on-error="onUploadError"
          :show-file-list="false"
          :file-list="fileList"
          :headers="uploadHeaders"
          :action="apiBaseUrl+'space.card/preImportFile'"
          :data="upLoadData">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div v-if="uploadProgress" class="el-upload__tip text-align-center" slot="tip">
          上传进度 <span class="display-inline-block w-36 text-align-right">{{uploadProgress}}% </span>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
  import request from '../../libs/api.request'

  export default {
    props: {
      model: {
        type: Object,
        default: () => {
        }
      }
    },
    data() {
      return {
        uploadHeaders: {},
        apiBaseUrl: request.baseUrl,
        uploadProgress: '',
        fileList: [],
        upLoadData: {}
      }
    },
    methods: {
      // 上传之前
      beforeUpload(file) {
        console.log('上传前', file);
        if (this.model.flds && this.model.flds.length !== 0) {
          this.upLoadData.mid = this.$store.state.models.id
        } else {
          this.$message({
            type: 'warning',
            message: '请选择模版！'
          })
          return
        }
      },
      // 上传中
      onUploadProgress(event, file) {
        this.uploadProgress = parseInt(event.percent)
      },
      // 上传成功
      onUploadSuccess(response, file) {
        if (response.code === 0) {
          this.uploadProgress = ''
          this.$message({
            type: 'success',
            message: ' 上传成功！'
          })
          this.$emit('upLoadSuccess', response.data.cardList)
        }
        if (response.code === 1) {
          this.uploadProgress = ''
          this.$message({
            type: 'warning',
            message: response.message
          })
        }
      },
      // 上传失败
      onUploadError() {
        this.$message.error('上传失败，请重试或检查网络')
      }
    },
    created() {
      // console.log(this.$cookies.get('authToken'));
      this.uploadHeaders = {
        authorization: this.$cookies.get('authToken')
      }
    }
  }
</script>

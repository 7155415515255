<template>
  <div class="remark-dialog">
    <div v-show="dialogVisible"
         class="bg-f position-fixed w-340 bdr-5 shadow-1 z-index-1 drag-dialog t-0 l-0">
      <div class="justify-content-space-between align-items-center">
        <div class="fs-18 c-2 pd-l-10 pd-t-18 pd-b-5 drag-dialog-header"
             style="cursor:move;width: 85%;user-select:none">助记
        </div>
        <i class="el-icon-close c-7 fs-20 mg-r-10 mg-t-10" @click="dialogVisible = false"></i>
      </div>
      <div class="pd-10">
        <el-input
            placeholder="请输入内容"
            v-model="text"
            maxlength="300"
            type="textarea"
            :autosize="{ minRows: 13 ,maxRows: 18}"
            show-word-limit>
        </el-input>
        <div class="row-reverse mg-b-10 position-relative">
          <div @click="save" style="top: -32px"
               class="w-48 btn h-24 bg-app-green position-absolute r-0 bdr-12 mg-t-15 align-items-center justify-content-center c-f fs-20">
            <i class="el-icon-check"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../libs/dialogDrag'
import request from '../libs/api.request'

export default {
  name: "RemarkDialog",
  props: {
    cardId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      dialogVisible: false,
      text: "",
    }
  },
  watch: {
    cardId() {
      this.text = '';
      if (this.cardId) {
        request.request({
          url: '/space.card/getRemark',
          method: 'get',
          params: {cardId: this.cardId}
        }).then((data) => {
          this.text = data.content;
        }).catch((e) => {
          this.$message.error(e);
        })
      }
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    save() {
      request.request({
        url: '/space.card/saveRemark',
        method: 'post',
        data: {cardId: this.cardId, content: this.text}
      }).then(() => {
        this.$message.success('已保存')
      }).catch((e) => {
        this.$message.error(e);
      })
    }
  },
  mounted() {
    const dialogHeaderEl = document.querySelector('.drag-dialog-header');
    const dragDom = document.querySelector('.drag-dialog');

    console.log("dialogHeaderEl", dialogHeaderEl);
    console.log("dragDom", dragDom);
    // var l = document.body.clientWidth / 2 - 200;
    var t = document.body.clientHeight / 2 - 200;
    dragDom.style.cssText += `;left:71%;top:${t}px;`;

    const sty = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr];
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr];
      }
    })()

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft;
      const disY = e.clientY - dialogHeaderEl.offsetTop;

      const screenWidth = document.body.clientWidth; // body当前宽度
      const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取)

      const dragDomWidth = dragDom.offsetWidth; // 对话框宽度
      const dragDomheight = dragDom.offsetHeight; // 对话框高度

      let minDragDomLeft = -dragDom.offsetLeft;
      let maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;
      const reservedDistance = 80;
      minDragDomLeft += -(dragDomWidth - reservedDistance);
      maxDragDomLeft += dragDomWidth - reservedDistance;

      let minDragDomTop = -dragDom.offsetTop;
      let maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight;
      maxDragDomTop += dragDomheight - reservedDistance;

      // 获取到的值带px 正则匹配替换
      let styL = sty(dragDom, 'left');
      let styT = sty(dragDom, 'top');

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (styL.includes('%')) {
        styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100);
        styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100);
      } else {
        styL = +styL.replace(/\px/g, '');
        styT = +styT.replace(/\px/g, '');
      }
      ;

      // 去掉对拖拽的响应，参考：https://blog.csdn.net/z467469274/article/details/77332830?utm_source=blogxgwz2
      let ondragstartBackup = document.ondragstart
      let ondragendBackup = document.ondragend
      document.ondragstart = function (ev) {
        ev.preventDefault();
      };
      document.ondragend = function (ev) {
        ev.preventDefault();
      };

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        // 边界处理
        if (left < minDragDomLeft) {
          left = minDragDomLeft;
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft;
        }

        if (top < minDragDomTop) {
          top = minDragDomTop;
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop;
        }

        // 移动当前元素
        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;
      };

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
        document.ondragstart = ondragstartBackup
        document.ondragend = ondragendBackup
      };
    }

  }
}
</script>

<style>
.remark-dialog .el-textarea__inner {
  padding: 0;
  padding-bottom: 30px;
  border: none;

}

.remark-dialog .el-textarea .el-input__count {
  left: 0;
  bottom: -5px
}

.skin-moon .btn {
  background-color: #a24451 !important;
}
</style>
<template>
  <el-drawer
    :title="title"
    :visible.sync="showDrawer_"
    :size="400"
    :direction="direction"
  >
    <div class="pd-20" style="overflow-y: scroll" v-if="title ==='选择记忆库'">
        <div class="mg-b-20 cursor-pointer move-home" @click="moveHome" v-if="showHome">主页</div>
      <select-deck @on-item-select="onDeckSelect" :decks="$store.state.decks" :open="true"></select-deck>
    </div>
    <div class="pd-20" v-else>
      <a @click="onModelSelect(model)" 
         v-for="model in drawerData"
         class="display-block mg-b-20 pd-20 bg-f4f bdr-10 model-item"
         :key="model.id">
        {{model.name}}
      </a> 
    </div>
  </el-drawer>
</template>

<script>
import SelectDeck from '../SelectDeck.vue'
export default {
  props:{
    showDrawer: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '',
    },
    // 打开方向
    direction: {
      type: String,
      default: 'ltr',
    },
    drawerData: {
      type: Object,
      default: () => {}
    },
    showHome :{
      type: Boolean,
      default: false
    }
  },
  components:{
    SelectDeck
  },
  data() {
    return {
      decks:{}
    }
  },
  methods: {
    moveHome() {
      this.$emit('moveHome')
    },
    onModelSelect(model) {
      this.$emit('modelSelect', model)
    },
    onDeckSelect(deck) {
      this.$emit('deckSelect',deck)
    }
  },
  computed: {
    showDrawer_ : {
      get() {
        // console.log(this.showDrawer);
        return this.showDrawer
      },
      set (v) {
        this.$emit("changeDrawer", v)
      }
    }
  }

};
</script>

<style scoped>
  .move-home:hover {
    color: #28BEA0;
  }
</style>
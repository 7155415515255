/**
 * 
 * @param {*需要解析的字符串} str 
 * @param {*需要的模板字段个数,默认2个(正面背面)} modelFields 
 */

function getReplaceStr(num) {
  let replaceStr = ''
  for (let i = 1; i <= num ; i++) {
    replaceStr+= '*'
  }
  return replaceStr
}

export const analyseField = (str, modelFields) => {
  /**
    * 裁剪规则
    * 裁剪划分字符串组：一个*到两个*之间的内容是第一个字段的内容，2个*到3个*之间的是第二个字段的，
    * 3个到4个之间的是第三个字段内容，第四个*到 到下一个*号是第四个字段
    * 
    */
  const arr = str.trim().split("\n\n")
  console.log(arr);
  let newArr = []
  arr.forEach(item => {
    let index = item.indexOf(getReplaceStr(modelFields + 1)) 
    if (index > 0 ) {
      var newItem = item.slice(0,index) 
      for (let i = 1; i <= modelFields; i++) {
        if (i === 1){
          newItem = newItem.replace('*','');
        } else { 
          newItem = newItem.replace(getReplaceStr(i), "\x1f");
        }
      }
    } else {
      for (let i = 1; i <= modelFields; i++) {
        if (i === 1){
          item = item.replace('*','');
        } else { 
          item = item.replace(getReplaceStr(i), "\x1f");
        }
      }
    }
    item = newItem || item
    let strArr = item.split('\x1f')
    if (strArr.length === 0) {
      return []
    }
    newArr.push(strArr)
})
  return newArr
}

/**
 * 节流函数
 * @param {* 要执行的函数} fn
 * @param {* 延迟触发的时间} delay
 * 
 */

export const throttle = (fn, delay) => {
  let beginTime = new Date().getTime()
  return function () {
    let currentTime = new Date().getTime()
    let _self = this
    let args = arguments
    if (currentTime - beginTime > delay) {
      if (triggerNow) {
        fn.apply(_self,args)
        beginTime = currentTime
      }else {
        triggerNow = true
        beginTime = currentTime
      }
    }
  }
}

/**
 * 防抖
 * @param {*} fn 
 * @param {*} delay 
 * @param {* 首次是否触发} triggerNow
 */
export const debounce = (fn, delay, triggerNow) => {
  let t = null
  return function () {
    let _self = this, args = arguments
    if (t) {
      clearTimeout(t)
    }
    if( triggerNow ) {
      let exec = !t
      t = setTimeout(() => {
        t = null
      }, delay);
      if( exec ) {
        fn.apply(_self, args)
      }
    } else {
      t = setTimeout(() => {
        fn.apply(_self, args)
      }, delay);
    }
  }
}
<template>
  <el-dialog :visible.sync="showUserLabelDialog" center top="10vh" title="选择喜欢的内容">
    <div class="position-relative">
      <div id="viewLike" class="bg-f pd-l-10 pd-r-10 position-relative" style="height: 550px; overflow-y: auto">
        <div v-for="(item,index) in levelList">
          <div class="pd-b-20 fs-14 text-strong">{{ item.text }}</div>
          <div class="listbox mg-l-10 mg-b-10">
            <div class="list display-inline-block text-align-center fs-14" v-for="(c,i) of item.children"
                 :key="item.level+'-'+c.subjectId"
                 :class="{checked:checkArr.includes(item.level+'-'+c.subjectId)}"
                 @click="checkedBox(item.level+'-'+c.subjectId)">{{ c.subjectName }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-fill">
        <el-button class="w-fill" type="success" @click="onItemClick">
          确定(已选{{ checkCount }}个)
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import request from '../libs/api.request'
import util from '../libs/util'

export default {
  name: "userLabel",
  data() {
    return {
      showUserLabelDialog: false,
      level: '',
      levelList: [],
      checkArr: [],
      checkCount: 0,
    }
  },
  props: [],
  methods: {
    onClose() {
      this.showUserLabelDialog = false;
      let timestamp = Date.parse(new Date()) / 1000;
      localStorage.setItem('user_label_popup', timestamp);
    },
    onChangeShow() {
      this.showUserLabelDialog = true;
    },
    // 点击多选标签
    checkedBox(i) {
      if (this.checkArr.includes(i)) {
        //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
        //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
        this.checkArr = this.checkArr.filter(function (ele) {
          return ele != i;
        });
      } else {
        this.checkArr.push(i);
      }
      this.checkCount = this.checkArr.length;
    },
    getUserLabel() {
      request.request({url: '/user/getUserLabel', method: 'post'}
      ).then((data) => {
        console.log(data);
        if (!data) {
          let timestamp = Date.parse(new Date()) / 1000;
          let tc = localStorage.getItem('user_label_popup');
          if ((timestamp - tc) > 86400) {
            //先不弹出了有用户反馈有点骚扰
            //this.showUserLabelDialog = true;
          }
        }
        if (!util.isEmpty(data.interests)) {
          this.checkArr = data.interests;
          this.checkCount = data.interests.length;
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    getSubjectByLevel() {
      request.request({url: '/subject/getSubjectByLevel', method: 'post',}).then((data) => {
        this.levelList = data;
      }).catch((err) => {
        console.log(err);
      })
    },
    onItemClick() {
      console.log(this.checkArr);
      let that = this;
      request.request({
        url: '/user/saveUserLabel',
        data: {interests: JSON.stringify(that.checkArr)},
        method: 'post'
      }).then((data) => {
        this.$message({
          message: '设置成功',
          type: 'success'
        });
        setTimeout(function () {
          location.reload();
        }, 100);
      }).catch((err) => {
        this.$message(err.message)
      })
    }
  },
  mounted() {
    let that = this;
    if (this.$parent.loginUser) {
      that.getUserLabel();
      this.getSubjectByLevel();
    }
  },
}
</script>

<style>
.listBox {
  display: flex;
  flex-wrap: wrap;
}

.list {
  border: 1px solid #e2e5ea;
  padding: 12px 18px;
  border-radius: 100px;
  margin-right: 25px;
  margin-bottom: 15px;
  cursor: pointer;
}

.checked {
  color: #FFFFFF;
  background: #28bea0;
  border: 1px solid #28bea0;
}

.van-button--primary {
  color: #fff;
  background-color: #28bea0;
  border: 1px solid #28bea0;
}

.el-button--success {
  color: #fff;
  background-color: #28bea0 !important;
  border-color: #28bea0 !important;
}

.el-dialog__body {
  padding: 0px 20px 10px 20px !important;
}

div::-webkit-scrollbar {
  width: 0px;
}

div::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
}

div::-webkit-scrollbar-track { /*滚动条底层颜色*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}

// 兼容Firefox、IE
div {
  scrollbar-width: 1px;
  scrollbar-base-color: green;
  scrollbar-track-color: red;
  scrollbar-arrow-color: blue;
  border-radius: 5px;
}
</style>

<template>
  <div class="container-h5">
    <!-- 头部 -->
    <div class="header bg-app-green  position-relative">
      <div class="bg-app-green h-40 "></div>
      <img class="my-bg-img position-absolute  t-0" src="/static/image/anki_card_bg@2x.png" width="100%">
      <div class="position-relative z-index-999 box">
        <div class="h-44 align-items-center">
          <div class="mg-l-20 c-2 text-strong">Anki 记忆卡</div>
          <a href="https://file.ankichinas.cn" class="mg-l-20 lh-20 resource text-strong c-2">资源网</a>
          <!-- <div class="mg-l-20 lh-20 resource text-strong c-2">资源网</div> -->
        </div>
        <div class="mg-t-30">
          <div class="fs-36 text-strong text-align-center lh-53">闪卡学习记忆工具</div>
          <div class="fs-16 mg-t-10 text-align-center lh-22 text-strong">背单词、考研、高考的提分利器</div>
          <div class="mg-t-21 display-flex justify-content-center fs-16">
            <a href="/space" class="bg-3 bdr-8 w-152 h-44 justify-content-center align-items-center"
               style="color:#00F3B8;">
              <img src="/static/image/btn_green.png" class="w-14 mg-r-10">
              进入网页版
            </a>
            <a href="#download" class="bg-3 bdr-8 w-152 h-44 justify-content-center align-items-center mg-l-30"
               style="color:#FFF567;">
              <img src="/static/image/btn_yellow.png" class="w-14 mg-r-10">
              下载客户端
            </a>
          </div>
        </div>
        <div class="mg-t-41">
          <img src="/static/image/anki_card_image_a@2x.png" id="bgimg" width="100%">
        </div>
      </div>
    </div>
    <!-- 内容图片 -->
    <div class="position-relative z-index-999">
      <div class="pd-t-31 ">
        <div class=" fs-22 text-strong text-align-center lh-25">无学科之分，万能的学习助手</div>
        <div class="text-align-center fs-14 mg-t-10 pd-l-38 pd-r-38 c-6 lh-23">
          不管你想学英语、日语、德语……还是法律、数学、语文、医学…我都能完美胜任，因为我是如纸笔一样的基础学习工具和一套领先于时代的学习方法的结合体，值得你终身使用的
        </div>
        <div class="text-align-center">
          <img src="/static/image/anki_card_image_b@2x.png" width="100%">
        </div>
      </div>
    </div>
    <div class="pd-t-31" style="background: #fff">
      <div class=" fs-22 text-strong text-align-center lh-34">科学的闪卡笔记，学习专家也在推荐</div>
      <div class="text-align-center fs-14 mg-t-5 pd-l-38 pd-r-38 c-6 lh-23">
        学习专家也推荐的学习方式，用闪卡笔记让大脑自动实现检索式学习，把前沿的科学学习方法应用在日常学习之中
      </div>
      <div class="text-align-center">
        <img src="/static/image/anki_card_image_c@2x.png" width="100%">
      </div>
    </div>
    <div class="bg-app-green position-relative">
      <img class="position-absolute l-0 t-0" src="/static/image/banner4-bg1.png" width="110px">
      <img class="position-absolute r-0 b-0" src="/static/image/banner4-bg2.png" width="320px">
      <div class="pd-t-31">
        <div class="fs-22 lh-25 text-strong text-align-center">机器复习算法，为你量身打造学习方案</div>
        <div class="text-align-center fs-14 lh-23 mg-t-10 pd-l-38 pd-r-38">
          通过记忆算法和个性化复习系统，可以根据每个人对不同知识点的
          难易程度，难的知识点多学习，简单的知识点少学习。如果没有计算机的诞生，这么科学高效的学习方式，几乎不可能实现，但现在，你也可以拥有了……
        </div>
        <div class="text-align-center position-relative z-index-999">
          <img src="/static/image/anki_card_image_d@2x.png" width="100%">
        </div>
      </div>
    </div>
    <div class="pd-t-31">
      <div class=" fs-22 text-strong text-align-center lh-34">独创主动练习法，让学习没有盲点</div>
      <div class="text-align-center fs-14 mg-t-5 pd-l-38 pd-r-38 c-6 lh-23">
        复习算法就是不用你考虑，让机器算法智能给你安排知识点的下次复习时间，这是极其省心的。而主动练习，是可以通过强大的搜索和学习行为筛选器，主动找出你想复习知识点进行巩固，实现人工与算法的完美结合
      </div>
      <div class="text-align-center">
        <img src="/static/image/anki_card_image_e@2x.png" width="100%">
      </div>
    </div>
    <div class="pd-t-31" style="background: #fff">
      <div class=" fs-22 text-strong text-align-center lh-34">海量优质学习资源，助你高效冲刺高分</div>
      <div class="text-align-center fs-14 mg-t-5 pd-l-38 pd-r-38 c-6 lh-23">
        近千万张闪卡，覆盖考研、考证、大学、高中、初中、英语、日语等各学科的知识点，为你节省大量制作知识闪卡的时间。
      </div>
      <div class="text-align-center">
        <img src="/static/image/anki_card_image_f@2x.png" width="100%">
      </div>
    </div>
    <!-- 立即下载 -->
    <div id="download" class="pd-t-31 h-232 box">
      <div class=" fs-22 lh-25 text-strong text-align-center">立即下载Anki记忆卡体验，胜过千言万语</div>
      <div class="text-align-center fs-14 mg-t-10 pd-l-38 pd-r-38 lh-18">
        同时支持IOS、Android、iPadOS、Web版本
      </div>
      <div class="display-flex mg-t-24 pd-l-40 pd-r-40 box justify-content-space-around">
        <a href="/downloadapp.html" target="_blank"
           class=" c-1 ">
          <div class="justify-content-center">
            <div class="w-60 h-60 justify-content-center align-items-center">
              <img src="/static/image/a.png" width="100%">
            </div>
          </div>
          <div class="text-align-center">
            <a class="fs-12 lh-34">Android</a>
          </div>
        </a>
        <a href="https://mp.weixin.qq.com/s?__biz=MzU2OTg0NTAxMw==&mid=2247483671&idx=1&sn=f041df5e0f187f9c3de0d10ecac05d89&chksm=fcf9356dcb8ebc7bde77daa3c70830400b07b8859f25b62ae957e16df718d537592bf7f60c10#rd"
           target="_blank" class=" c-1">
          <div class="justify-content-center">
            <div class="w-60 h-60 justify-content-center align-items-center">
              <img src="/static/image/b.png" width="100%">
            </div>
          </div>
          <div class="text-align-center">
            <span class="fs-12 lh-34">IOS</span>
          </div>
        </a>
        <a class=" c-1">
          <div class="justify-content-center">
            <div class="w-60 h-60 justify-content-center align-items-center">
              <img src="/static/image/c.png" width="100%">
            </div>
          </div>
          <div class="text-align-center">
            <span class="fs-12 lh-34">MacOS</span>
          </div>
        </a>
        <a href="/space" class=" c-1">
          <div class="justify-content-center">
            <div class="w-60 h-60 justify-content-center align-items-center">
              <img src="/static/image/d.png" width="100%">
            </div>
          </div>
          <div class="text-align-center">
            <span class="fs-12 lh-34">网页版本</span>
          </div>
        </a>
      </div>
    </div>
    <div class="pd-b-13 display-flex column align-items-center" style="background-color: #17BFA0">
      <div class="mg-t-12 fs-12 lh-14">联系我们：ankimemory@163.com</div>
      <div class="text-align-center fs-12 lh-18 box pd-l-14 pd-r-14">ANKI记忆卡 © {{ currentYear }} ankimemory.com. All
        Rights Reserved.
        <a class="c-0" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备16042789号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "H5",
  data() {
    return {
      currentYear: ''
    }
  },
  mounted() {
    this.$parent.hideNavigateBar();
    this.currentYear = new Date().getFullYear()
  }
}
</script>

<style scoped>
.container-h5 {
  width: 100%;
  overflow: hidden;
}

.header {
  width: 100%;
  overflow: hidden;
}

.resource {
  opacity: .6;
}

.my-bg-img {
  right: -20px;
}
</style>

<template>
  <div class="mg-b-10 mg-t-10 w-b-93 myInput" >
    <el-input
      v-model="value"
      type="textarea"
      :autosize="{ minRows: 18 ,maxRows: 20}"
      @input="iptChange"
      :placeholder="placeholder"
      >
    </el-input>    
  </div>
</template>

<script>

import { analyseField, debounce } from '../../libs/analyse'
import { mapGetters } from 'vuex'
export default {
  props:{
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      value: '',
      placeholder: `示例\n*狗有几条腿？\n**狗有四条腿\n\n*挖空匹配规则\n**我是{{挖空内容}}`
    }
  },
  methods:{
    iptChange: debounce(function( ) {
      if (this.model.flds && this.model.flds.length !== 0) {
        if (this.value.trim() === '') {
          this.$emit('handleCardList', [])
        }else {
          const cardList = analyseField(this.value, this.model.flds.length)
          this.$emit('handleCardList', cardList)
        }
      }else {
        this.$message({
          type:'warning',
          message:"请先选择模板！"
        })
      }
    },1000, false),
  },
  computed: {
    ...mapGetters(['getModel', 'getDeck'])
  },
  watch: {
    getModel: {
      handler: function(newVal, oldValue) {
        if (this.value.trim() !== '') {
          this.iptChange()
        }
        console.log('model change');
      },
      deep: true
    }
  },
}
</script>

<style  scoped>
.myInput .el-textarea >>>.el-textarea__inner {
  width: 610px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import ElementUI from 'element-ui';
import './assets/el-theme/theme/index.css'
import './assets/ck-editor-skin.css'
import './assets/skin.css'
import './assets/albb-iconfont.css'
import createPersistedstate from 'vuex-persistedstate'
import { Icon } from 'vant';
import 'vant/lib/icon/index.css'
Vue.use(Icon)
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(ElementUI);
Vue.config.devtools = true;

import Index from '@/views/Index';
import Home from '@/views/Home';
import Deck from '@/views/Deck';
import Login from '@/views/Login';
import Make from '@/views/Make';
import Test from '@/views/Test';
import H5 from '@/views/H5'

let router = new VueRouter({
  mode: 'history', routes: [{
    path: '/', component: Index, meta: {
      title: 'Anki记忆卡官网-背单词、考研、高考的提分神器', content: {
        keywords: '无学科之分，万能的学习助手',
        description: '不管你想学英语、日语、德语……还是法律、数学、语文、医学…我都能完美胜任，因为我是如纸笔一样的基础学习工具和一套领先于时代的学习方法的结合体，值得你终身使用的'
      }, KeepAlive: false
    },
  }, {
    path: '/download', component: Index, meta: {
      title: 'Anki记忆卡', content: {keywords: '', description: ''}, KeepAlive: false
    },
  }, {
    path: '/space', component: Home, meta: {
      title: 'Anki记忆卡', content: {keywords: '', description: ''}, KeepAlive: false
    },
  }, {
    path: '/space/deck/:did', component: Deck, meta: {
      title: 'Anki记忆卡', content: {keywords: '', description: ''}, KeepAlive: false
    },
  }, {
    path: '/login', component: Login, meta: {
      title: 'Anki记忆卡', content: {keywords: '', description: ''}, KeepAlive: false
    },
  }, {
    path: '/qqcb', component: Login, meta: {
      title: 'Anki记忆卡', content: {keywords: '', description: ''}, KeepAlive: false
    },
  }, {
    path: '/test', component: Test, meta: {
      title: 'Anki记忆卡', content: {keywords: '', description: ''}, KeepAlive: false
    },
  }, {
    path: '/make', component: Make, name: 'make', meta: {
      title: '批量制卡',
      content: {keywords: '', description: ''},
      KeepAlive: false,
      viewport: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    },
  }, {
    path: '/h5', component: H5, meta: {
      title: 'Anki记忆卡官网-背单词、考研、高考的提分神器', content: {
        keywords: '无学科之分，万能的学习助手',
        description: '不管你想学英语、日语、德语……还是法律、数学、语文、医学…我都能完美胜任，因为我是如纸笔一样的基础学习工具和一套领先于时代的学习方法的结合体，值得你终身使用的'
      }, KeepAlive: false
    },
  }]
})

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?98c4c18d2378854d32deb76dff94bffc";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});

const store = new Vuex.Store({
  state: {
    loginUser: null, decks: {}, models: {},// 选择的模版
    deck: {} //选择的记忆库
  }, mutations: {
    setLoginUser(state, user) {
      state.loginUser = user;
    }, setDecksData(state, deck) {
      state.decks = deck
    }, setModelsData(state, model) {
      state.models = model
    }, setDeckData(state, deck) {
      state.deck = deck
    }
  }, getters: {
    getModel(state) {
      return state.models
    }, getDeck(state) {
      return state.deck
    },
  }, plugins: [createPersistedstate({
    storage: window.sessionStorage
  })] // 持久化插件
})


Vue.config.productionTip = false

new Vue({
  router, store, render: h => h(App), mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');


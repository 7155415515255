<template>
  <div class="align-items-center justify-content-space-between w-500 my-container">
    <div class="align-items-center mg-l-20">
      <a class="c-app-green text-ellipsis-1" @click="onSelectDeckClick">
        <span v-if="deck.simpleName"><i class="el-icon-caret-bottom"></i>{{ deck.simpleName }}</span>
        <span v-else><i class="el-icon-caret-bottom"></i>选择记忆库</span>
      </a>
      <a @click="onSelectModelClick" class="mg-l-20 c-app-green text-ellipsis-1">
        <span v-if="model.name"><i class="el-icon-caret-bottom"></i>{{ model.name }}</span>
        <span v-else><i class="el-icon-caret-bottom"></i>选择模板</span>
      </a>
    </div>
    <select-drawer 
        @changeDrawer="changeDrawer" 
        @modelSelect="modelSelect" 
        @deckSelect="deckSelect"
        :showDrawer="showDrawer" 
        :title="title" 
        :drawerData="modelsData"> 
    </select-drawer>
  </div>
</template>

<script>
import request from '../../libs/api.request'

import SelectDrawer from './SelectDrawer.vue'
export default {
  components: {
    SelectDrawer
  },
  data() {
    return {
      modelsData: {},
      title: '',
      deck: '',
      model:'',
      showDrawer: false ,
    }
  },
  methods : {
    onSelectDeckClick() {
      this.showDrawer = true
      this.title = '选择记忆库'
    },
    changeDrawer(value) {
      this.showDrawer = value
      this.showModelDrawer = value
    },
    onSelectModelClick() {
      if(!this.deck.simpleName) {
        this.$message.warning('请先选择记忆库')
      }else {
        this.showDrawer = true
        this.title = '选择模板'
      } 
    },
    modelSelect(model) {
      this.model = model
      this.showDrawer = false
      this.$emit('setModelsData',model)
      this.$store.commit('setModelsData', model)
    },
    deckSelect(deck) {
      this.deck = deck
      this.showDrawer = false
      this.$store.commit('setDeckData', deck)
    },
    async getModels() {
      const res = await request.request({url: '/space.index/models',})
      this.modelsData = res.models
    }
  },
  created () {
    this.getModels()
  },
  mounted() {
    if (this.$store.state.models && this.$store.state.deck) {
      this.model = this.$store.state.models
      this.deck = this.$store.state.deck
    }
  }
}
</script>

<style scoped>
  .my-container {
    box-sizing: border-box;
  }
</style>